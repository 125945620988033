import { Helmet } from "react-helmet";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarMini from "../../Components/Navbar/NavbarMini";
import Footer from "../../Components/Footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { useState } from "react";
import { FaUsers } from "react-icons/fa";

const Datathon = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const images = [
    "IMG_3159.jpg",
    "IMG_3161.jpg",
    "IMG_3163.jpg",
    "IMG_5101.jpg",
    "IMG_5102.jpg",
    "IMG_5103.jpg",
    "IMG_5104.jpg",
    "IMG_5105.jpg",
  ];

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage(null);
  };

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="relative">
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 text-white text-3xl p-4"
            >
              &times;
            </button>
            <img
              src={currentImage}
              alt="Current Preview"
              className="max-w-full max-h-screen object-contain"
            />
          </div>
        </div>
      )}
      <div className="bg-slate-100 dark:bg-slate-900 dark:text-slate-200">
        <Helmet>
          <title>DATATHON CONTEST FALL 2024 || DIUCPC</title>
          <meta
            name="description"
            content="The Datathon Contest, or Data Science Hackathon, showcased participants' exceptional skills in data analysis, visualization, and machine learning."
          />
          <meta
            property="og:title"
            content="DATATHON CONTEST FALL 2024 || DIUCPC"
          />
          <meta
            property="og:description"
            content="The Datathon Contest, or Data Science Hackathon, showcased participants' exceptional skills in data analysis, visualization, and machine learning. Competitors tackled complex datasets and created impactful solutions, pushing the limits of modern data tools. The prize-giving ceremony honored their achievements at the Prof. Dr. Aminul Islam Seminar Hall, Daffodil International University."
          />
          <meta property="og:image" content="%PUBLIC_URL%/DIUCPC-logo.png" />
          <meta
            property="og:url"
            content="https://cpc.daffodilvarsity.edu.bd/prompt"
          />
        </Helmet>

        {/* Navbar */}
        <div>
          <div className="hidden xl:block">
            <Navbar></Navbar>
            <div className="h-12 2xl:h-16"></div>
          </div>
          <div className="xl:hidden">
            <NavbarMini></NavbarMini>
          </div>
        </div>

        <div className="mt-5 md:mt-10 min-h-[50vh] md:min-h-[70vh] w-11/12 xl:w-3/4 rounded-md mx-auto">
          <div className="mt-5">
            <Swiper
              initialSlide={0}
              spaceBetween={50}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              grabCursor={true}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              effect={"coverflow"}
              modules={[Autoplay, Pagination]}
            >
              {images?.map((image, index) => (
                <SwiperSlide>
                  <div className="w-full h-[400px]" key={index}>
                    <img
                      src={`./images/datathon/${image}`}
                      alt={image}
                      className="h-full w-full object-cover"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <h2 className="text-3xl font-bold text-center mt-5">
            DATATHON CONTEST FALL 2024
          </h2>
          <div>
            <p className="mt-5 text-lg leading-8">
              The Datathon Contest, or Data Science Hackathon, showcased
              participants' exceptional skills in data analysis, visualization,
              and machine learning. Competitors tackled complex datasets and
              created impactful solutions, pushing the limits of modern data
              tools. The prize-giving ceremony honored their achievements at the
              Prof. Dr. Aminul Islam Seminar Hall, Daffodil International
              University.
            </p>
          </div>
          <div className="mt-20 text-3xl font-medium flex md:flex-row flex-col items-center md:gap-x-2 gap-y-2 justify-center">
            <div className="flex items-center gap-x-2">
              <FaUsers size={40} />
              <h2>Participants: </h2>
            </div>
            <b>95+</b>
          </div>
          <div>
            <h4 className="text-center text-4xl font-bold mt-20 mb-5">
              Winners
            </h4>
            <div>
              <div className="flex justify-center">
                <Card
                  image={"./images/prompt/profile.jpeg"}
                  name={"Md. Anupam Abir Kolin"}
                  position={"Champion"}
                  openModal={openModal}
                />
              </div>
              <div className="grid md:grid-cols-3 grid-cols-2 gap-5 items-center justify-between w-full mt-10">
                {Winners?.map((winner) => (
                  <Card
                    image={winner?.image}
                    name={winner?.name}
                    position={winner?.position}
                    openModal={openModal}
                  />
                ))}
              </div>
            </div>
          </div>
          <div>
            <h4 className="text-center text-4xl font-bold mt-20 mb-5">
              Problem Setters
            </h4>
            <div className="grid md:grid-cols-3 grid-cols-2 gap-5 items-center justify-between w-full mt-10">
              {ProblemSetters?.map((winner) => (
                <Card
                  image={winner?.image}
                  name={winner?.name}
                  position={""}
                  openModal={openModal}
                />
              ))}
            </div>
          </div>
          <div>
            <h4 className="text-center text-4xl font-bold mt-20 mb-5">
              Judges
            </h4>
            <div className="grid md:grid-cols-3 grid-cols-2 gap-5 items-center justify-between w-full mt-10">
              {Judges?.map((winner) => (
                <Card
                  image={winner?.image}
                  name={winner?.name}
                  position={""}
                  openModal={openModal}
                />
              ))}
            </div>
          </div>
          <div>
            <h4 className="text-center text-4xl font-bold mt-20 mb-5">
              Images
            </h4>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
              {GalleryImages.map((image, index) => (
                <div key={index} className="relative">
                  <img
                    src={`./images/datathon/${image}`}
                    alt={`./images/datathon/${image}`}
                    className="w-full h-auto rounded-lg cursor-pointer transition-all hover:scale-105"
                    onClick={() => openModal(`./images/prompt/${image}`)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="mt-10">
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};

export default Datathon;

const GalleryImages = [
  "IMG_3161.jpg",
  "IMG_5101.jpg",
  "IMG_5102.jpg",
  "IMG_5103.jpg",
  "IMG_5104.jpg",
  "IMG_3159.jpg",
  "IMG_3163.jpg",
  "IMG_5105.jpg",
];

const Card = ({ image, name, position, openModal }) => {
  return (
    <div className="p-1.5 shadow-sm shadow-gray-400 bg-white justify-self-center w-60 rounded-lg">
      <div
        className="rounded-xl overflow-hidden h-40 w-full"
        onClick={() => openModal(image)}
      >
        <img className="w-full h-full" src={image} alt={name} />
      </div>
      <div className="text-center mt-1.5 py-1">
        <p className="font-semibold text-gray-400">{position}</p>
        <h6 className="text-xl font-bold mt-2">{name}</h6>
      </div>
    </div>
  );
};

const Winners = [
  {
    name: "Daiyaan Muhammad Fardeen",
    image: "./images/prompt/profile.jpeg",
    position: "1st Runner Up",
  },
  {
    name: "Ali Ahsan Mohammad Mojahid",
    image: "./images/prompt/profile.jpeg",
    position: "2nd Runner Up",
  },
  {
    name: "Fahmida Ahmed Tonni",
    image: "./images/prompt/profile.jpeg",
    position: "Female Champion",
  },
];

const ProblemSetters = [
  {
    name: "Pranto Saha",
    image: "./images/prompt/profile.jpeg",
  },
  {
    name: "Ishtiaque Ahmed",
    image: "./images/prompt/profile.jpeg",
  },
];

const Judges = [
  {
    name: "Ms. Nusrat Khan",
    image: "./images/prompt/profile.jpeg",
  },
  {
    name: "Pranto Saha",
    image: "./images/prompt/profile.jpeg",
  },
  {
    name: "Ishtiaque Ahmed",
    image: "./images/prompt/profile.jpeg",
  },
  {
    name: "Dr. Arif Mahmud",
    image: "./images/prompt/profile.jpeg",
  },
];
